import React from 'react';
import './Sustainability.css';
import SustainabilityVideo from './250227_Sustainability Website Video_v4_Compressed.mp4';
import SustainabilityRow from './SustainabilityRow.tsx';
import EcoPackaging from './EcoPackaging.tsx';
import sustainabilityTile01 from './sustainability_tile_01.png'
import sustainabilityTile02 from './sustainability_tile_02.png'
import sustainabilityTile03 from './sustainability_tile_03.png'
import sustainabilityTile04 from './sustainability_tile_04.png';
import sustainabilityTile05 from './sustainability_tile_05.png' // You'll need to add this image
import TwoProductView from './TwoProductView.tsx';
import ProductInfo from './ProductInfo.tsx';

const Sustainability = () => {
    return (
        <div className="sustainability-container">
            <section className="sustainability-top">
                <div className="video-container">
                    <video 
                        autoPlay 
                        loop 
                        muted 
                        playsInline
                        className="sustainability-video"
                    >
                        <source src={SustainabilityVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <h1 className="sustainability-title">CULTIVATION METHODS</h1>
                <div className="sustainability-divider"></div>
                <div className="content-columns">
                    <div className="sustainability-quote">
                        <p className="desktop-quote">
                            Sustainablity is at the heart of everything <br></br>
                            we do. Our farming methods reduce <br></br>
                            significantly our environmental impact so <br></br> 
                            you can consume worry-free.
                        </p>
                        <p className="mobile-quote">
                            Sustainablity is at the <br></br>heart of everything 
                            we do. <br></br>Our farming methods <br></br>reduce 
                            significantly our <br></br>environmental impact <br></br>so  
                            you can consume<br></br>worry-free.
                        </p>
                    </div>
                </div>
            </section>
            
            <div className="sustainability-rows-container">
                <SustainabilityRow 
                    image={sustainabilityTile02} 
                    imageAlt="Sustainability Practice 1" 
                    title="100% PESTICIDE-FREE"
                    subtitleMobile="Cannabis the way nature 
                    intended. Instead of using 
                    pesticides and harmful chemicals,
                    we put beneficial bugs to work."
                    subtitleDesktop="Cannabis the way nature 
                    intended. </br> Instead of using 
                    pesticides and</br>  harmful chemicals,
                    we put </br>  beneficial bugs to work."
                    className="traditional-practice" 
                />
                <SustainabilityRow 
                    image={sustainabilityTile03} 
                    imageAlt="Sustainability Practice 1" 
                    title="CLOSED-LOOP IRRIGATION"
                    subtitleDesktop="Growing cannabis requires a </br> significant
                    amount of water. We </br>capture and recycle all
                    water used, </br>never tapping into 
                    the public water supply."
                    subtitleMobile="Growing cannabis requires a significant
                    amount of water. We capture and recycle all
                    water used, never tapping into 
                    the public water supply."
                    className="traditional-practice" 
                />
                <SustainabilityRow 
                    image={sustainabilityTile04} 
                    imageAlt="Sustainability Practice 1" 
                    title="SUSTAINABLE POWER"
                    subtitleDesktop="Our cultivation facilities use 
                    LED </br>lighting, minimizing our</br> electricity usage.
                    Wherever </br>possible, our farms are powered 
                    by</br> clean hydro energy."
                    subtitleMobile="Our cultivation facilities use 
                    LED lighting, minimizing our electricity usage.
                    Wherever possible, our farms are powered 
                    by clean hydro energy."
                    className="traditional-practice" 
                />
                <SustainabilityRow 
                    image={sustainabilityTile05} 
                    imageAlt="Sustainability Practice 1" 
                    title="LANDFILL DIVERSION"
                    subtitleDesktop="All plant waste is composted 
                    and </br>turned into organic soil 
                    so it </br>never ends up in a
                    landfill."
                    subtitleMobile="All plant waste is composted 
                    and turned into organic soil 
                    so it never ends up in a
                    landfill."
                    className="traditional-practice" 
                />
            </div>
            
            <EcoPackaging />
            
            <section className="sustainability-bottom">
                <div className="content-columns">
                    <div className="issue-column">
                        {/* Empty column for alignment */}
                    </div>
                </div>
                <TwoProductView />
                <div className="sustainability-divider-alt"></div>
                <ProductInfo />
            </section>

        </div>
    );
};

export default Sustainability;